import React, { useEffect } from 'react';
import axios from 'axios';
import './LoadingScreen.css'; // Your styles
import myPhoto from './assets/Logo.png'; // Import the photo
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import {
  browserName,
  browserVersion,
  mobileVendor,
  mobileModel,
  osName,
  osVersion,
  engineName,
  deviceType,
  isMobile,
  isTablet,
  isDesktop,
} from 'react-device-detect';

// Function to get GPU information
const getGPUInfo = () => {
  const canvas = document.createElement('canvas');
  const gl = canvas.getContext('webgl') || canvas.getContext('experimental-webgl');
  if (!gl) return 'Unknown GPU';

  const debugInfo = gl.getExtension('WEBGL_debug_renderer_info');
  const gpuInfo = debugInfo ? gl.getParameter(debugInfo.UNMASKED_RENDERER_WEBGL) : 'Unknown GPU';
  return gpuInfo;
};

// Function to get connection type information
const getConnectionType = () => {
  const connection = navigator.connection || navigator.mozConnection || navigator.webkitConnection;
  return connection ? connection.effectiveType || 'Unknown' : 'Unknown';
};

const LoadingScreen = () => {
  const url = "https://pasting.org/api/v1"; // Your base API URL

  const { shortUrl } = useParams(); // Extract shortUrl from the path
  const location = useLocation(); // For query parameters
  const navigate = useNavigate();

  const getQueryParams = () => {
    const searchParams = new URLSearchParams(location.search);
    return {
      encryptText: searchParams.get('encryptText'),
    };
  };

  // Fetch redirect information from the backend
  const fetchRedirectInfo = async (shortUrl) => {
    try {
      const response = await axios.get(`${url}/PasteDetails/${shortUrl}`);  // Call backend API
      if (response.data.encryptText) {
        // If encryptText is received, store it in state or perform logic without redirect
        // Example: You can pass this encryptText to the next step
        console.log("Received encryptText:", response.data.encryptText);
        // Optional: Directly navigate to a different page (e.g., ResultPage)
        // navigate(`/result/${response.data.encryptText}`);
      }
    } catch (error) {
      console.error('Error fetching redirect info:', error);
      navigate('/not-found');  // Navigate to NotFound page if there's an error
    }
  };

  // Fetch IP and location
  const fetchIpAndLocation = async () => {
    try {
      const response = await axios.get(`${url}/IPInfo`);
      const data = response.data;
      return {
        ip: data.ip,
        city: data.city,
        region: data.region,
        country: data.country,
      };
    } catch (error) {
      console.error('Error fetching IP and location:', error);
      return {
        ip: 'Unknown',
        city: 'Unknown',
        region: 'Unknown',
        country: 'Unknown',
      };
    }
  };

  // Get system information from the browser
  const getSystemInfo = async () => {
    console.log("getSystemInfo is runnig");
    const ipLocation = await fetchIpAndLocation();
    console.log("ipLocation:",ipLocation);
    const gpuInfo = getGPUInfo();
    const connectionType = getConnectionType();
    return {
      screenWidth: window.screen.width,
      screenHeight: window.screen.height,
      windowWidth: window.innerWidth,
      windowHeight: window.innerHeight,
      colorDepth: window.screen.colorDepth,
      pixelDepth: window.screen.pixelDepth,
      hardwareConcurrency: navigator.hardwareConcurrency || 'Unknown',
      platform: navigator.platform || 'Unknown',
      userAgent: navigator.userAgent,
      language: navigator.language,
      appVersion: navigator.appVersion,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      ip: ipLocation.ip,
      city: ipLocation.city,
      region: ipLocation.region,
      country: ipLocation.country,
      browserName: browserName,
      browserVersion: browserVersion,
      mobileVendor: mobileVendor || 'N/A',
      mobileModel: mobileModel || 'N/A',
      osName: osName,
      osVersion: osVersion,
      engineName: engineName,
      deviceType: deviceType,
      isMobile: isMobile,
      isTablet: isTablet,
      isDesktop: isDesktop,
      gpuInfo: gpuInfo,
      connectionType: connectionType,
    };
  };

  // Send system info to backend
  const sendSystemInfo = async (encryptText) => {
    const systemInfo = await getSystemInfo();
    try {
      const response = await axios.post(
        `${url}/FetchUsersData`, // API endpoint for the backend method
        {
          systemInfo,
          EncryptText: encryptText,
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );

      // If a redirection URL is returned from the backend, handle the redirect
      const { redirectUrl } = response.data;
      if (redirectUrl) {
        // Perform the redirection in the frontend
        window.location.href = redirectUrl;
      }
    } catch (error) {
      console.error('Error sending system info:', error);
    }
  };

  useEffect(() => {
    if (shortUrl) {
      fetchRedirectInfo(shortUrl);
      sendSystemInfo(shortUrl);
    }
  }, [shortUrl,location.search]);


  return (
    <div className="loading-container">
      <img src={myPhoto} alt="My Photo" className="header-image-loading" />
      <div className="dots">
        <span className="dot dot1"></span>
        <span className="dot dot2"></span>
        <span className="dot dot3"></span>
      </div>
    </div>
  );
};

export default LoadingScreen;
