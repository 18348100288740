import React, { useState, useEffect, useRef } from 'react';
import './ExpirationForm.css';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import Navbar from './Navbar';
import Footer from './Footer';
import axios from 'axios';

const ExpirationForm = () => {
  const [expiration, setExpiration] = useState('');
  const [description, setDescription] = useState('');
  const [titleText, setTitleText] = useState('');
  const [selectedOption, setSelectedOption] = useState('option1'); // Default to "Text"
  const [comments, setComments] = useState('');
  const [encryptStatus, setEncryptStatus] = useState(false);
  const [encryptLoading, setEncryptLoading] = useState(false);
  const [linkExpireOnDownload, setLinkExpireOnDownload] = useState(false);
  const [loading, setLoading] = useState(false);
  const [responseMessage, setResponseMessage] = useState('');
  const [url, setUrl] = useState('');
  const [apiUrl, setApiUrl] = useState('');
  const [animateText, setAnimateText] = useState(false); // For animation control

  const toastRef = useRef(null);
  const responseRef = useRef(null);

  useEffect(() => {
    fetch('/config.json')
      .then((res) => res.json())
      .then((config) => setApiUrl(config.url))
      .catch((err) => console.error('Error loading config.json:', err));
  }, []);

  useEffect(() => {
    if (responseMessage || url) {
      responseRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }, [responseMessage, url]);

  useEffect(() => {
    // Trigger animation for "Submit Your Paste"
    const timer = setTimeout(() => setAnimateText(true), 500); // Delay before animation
    return () => clearTimeout(timer);
  }, []);

  const handleEncrypt = () => {
    setEncryptStatus(true);
    setEncryptLoading(true);

    setTimeout(() => {
      setEncryptLoading(false);

      toastRef.current.show({
        severity: 'success',
        summary: 'Success',
        detail: 'Paste encrypted successfully!',
        life: 3000,
      });
    }, 2000);
  };

  const handleSubmit = async () => {
    if (!selectedOption) {
      toastRef.current.show({
        severity: 'error',
        summary: 'Error',
        detail: 'Please fill in all required fields: Select Option.',
        life: 3000,
      });
      return;
    }

    setLoading(true);
    const formData = {
      expiration,
      description,
      titleText,
      selectedOption,
      comments,
      encryptStatus,
      LinkExpireAfterFirstDownload: linkExpireOnDownload,
    };

    try {
      const response = await axios.post(`${apiUrl}/PasteDetails`, formData, {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      setResponseMessage('Success: Submission successful!');
      setUrl(response.data.data.url || '');

      setExpiration('');
      setDescription('');
      setTitleText('');
      setSelectedOption('');
      setComments('');
      setEncryptStatus(false);
      setLinkExpireOnDownload(false);

      setTimeout(() => {
        setUrl('');
        setResponseMessage('');
      }, 30000);
    } catch (error) {
      if (!error.response) {
        toastRef.current.show({
          severity: 'error',
          summary: 'Server Unreachable',
          detail: 'The server is currently unavailable. Please try again later.',
          life: 5000,
        });
      } else if (error.code === 'ECONNABORTED') {
        toastRef.current.show({
          severity: 'error',
          summary: 'Timeout',
          detail: 'The server took too long to respond. Please try again later.',
          life: 5000,
        });
      } else {
        toastRef.current.show({
          severity: 'error',
          summary: 'Server Unreachable',
          detail: 'The server is currently unavailable. Please try again later.',
          life: 5000,
        });
      }
      setUrl('');
    } finally {
      setLoading(false);
    }
  };

  const handleCopyUrl = () => {
    if (url) {
      navigator.clipboard
        .writeText(url)
        .then(() => {
          toastRef.current.show({
            severity: 'success',
            summary: 'URL copied!',
            detail: 'The URL has been copied to your clipboard.',
            life: 1000,
          });
        })
        .catch(() => {
          toastRef.current.show({
            severity: 'error',
            summary: 'Copy failed',
            detail: 'Failed to copy URL.',
            life: 1000,
          });
        });
    }
  };

  return (
    <div className="layout">
      <Toast ref={toastRef} />
      <Navbar />
      <main className="main-content">
        <div className="form-container">
          {/* Animated "Submit Your Paste" */}
          <h2 className={`form-title ${animateText ? 'animate' : ''}`}>Submit Your Paste</h2>

          <div className="form-group">
            <label htmlFor="expirationSelect" className="form-label">
              Expiration
            </label>
            <select
              className="form-select"
              id="expirationSelect"
              value={expiration}
              onChange={(e) => setExpiration(e.target.value)}
            >
              <option value="">Select an option</option>
              <option value="1">1 Day</option>
              <option value="7">1 Week</option>
              <option value="30">1 Month</option>
              <option value="365">1 Year</option>
            </select>
          </div>

          <div className="form-group mt-3">
            <label htmlFor="descriptionInput" className="form-label">
              Description
            </label>
            <input
              className="form-control"
              id="descriptionInput"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              placeholder="Enter description"
            />
          </div>

          <div className="form-group mt-3">
            <div className="form-check">
              <input
                type="checkbox"
                className="form-check-input"
                id="linkExpireCheckbox"
                checked={linkExpireOnDownload}
                onChange={(e) => setLinkExpireOnDownload(e.target.checked)}
              />
              <label htmlFor="linkExpireCheckbox" className="form-check-label">
                Do you want the link to expire after the first download?
              </label>
            </div>
          </div>

          <div className="form-group mt-4">
            <label htmlFor="pasteInput" className="form-label">
            </label>
            <div className="card mt-2 custom-card">
              <div className="card-body">
                <div className="row">
                  <div className="col-md-6">
                    <label htmlFor="pasteInputField" className="form-label">
                      Title Text
                    </label>
                    <input
                      type="text"
                      id="pasteInputField"
                      className="form-control custom-input"
                      value={titleText}
                      onChange={(e) => setTitleText(e.target.value)}
                      placeholder="Enter your text here"
                    />
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="pasteDropdown" className="form-label">
                      Select Option
                    </label>
                    <select
                      id="pasteDropdown"
                      className="form-select custom-dropdown"
                      value={selectedOption}
                      onChange={(e) => setSelectedOption(e.target.value)}
                    >
                      <option value="">Choose option</option>
                      <option value="option1">Text</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="form-floating mt-4">
            <textarea
              className="form-control no-resize"
              placeholder="Leave a comment here"
              id="floatingTextarea2"
              value={comments}
              onChange={(e) => setComments(e.target.value)}
            ></textarea>
            <label htmlFor="floatingTextarea2">Text</label>
          </div>

          <div className="form-group mt-4 d-flex justify-content-start gap-3">
            <Button
              label={encryptStatus ? 'Encrypted' : 'Encrypt paste'}
              icon={encryptStatus ? 'pi pi-check' : 'pi pi-times'}
              className={`p-button ${encryptStatus ? 'p-button-success' : 'p-button-danger'} rounded-button`}
              onClick={handleEncrypt}
              loading={encryptLoading}
              disabled={!comments.trim()}
            />
          </div>

          <hr />

          <div className="submit-button-container">
            <Button
              label="Submit"
              icon="pi pi-check"
              className="rounded-button"
              onClick={handleSubmit}
              loading={loading}
              disabled={loading || !comments.trim()}
            />
          </div>

          {responseMessage && <hr className="result-divider" />}

          {responseMessage && (
            <div className="response-message mt-4" ref={responseRef}>
              <p>{responseMessage}</p>
              {url && (
                <div className="url-container">
                  <div className="url-box">
                    <a href={url} target="_blank" rel="noopener noreferrer">
                      {url}
                    </a>
                  </div>
                  <Button
                    label="Copy URL"
                    icon="pi pi-copy"
                    className="p-button-success p-button-sm rounded-button"
                    onClick={handleCopyUrl}
                  />
                </div>
              )}
            </div>
          )}
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default ExpirationForm;
