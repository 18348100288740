import React from 'react';
import Layout from './Layout';

const Contact = () => {
  return (
    <Layout>
      <div className="contact-page">
        <h1>Contact Us</h1>
        <p>We’d love to hear from you! If you have questions, suggestions, or concerns, reach out to us:</p>
        <ul>
          <li>Email: <a href="mailto:support@pasting.org">support@pasting.org</a></li>
          <li>Phone: +1 (123) 456-7890</li>
          <li>Address: 123 Pasting Lane, Privacy City, PC 12345</li>
        </ul>
      </div>
    </Layout>
  );
};

export default Contact;
