import React from 'react';
import './Footer.css'; // If you want to style the footer separately

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-about">
          <h4>Why Use Pasting?</h4>
          <ul>
            <li><strong>Privacy-first design:</strong> Your content is only accessible via the unique link you generate.</li>
            <li><strong>Expiration options:</strong> Automatically delete content after the specified duration to maintain privacy and declutter shared information.</li>
            <li><strong>Ease of use:</strong> No signup or login required—just generate and share links effortlessly.</li>
          </ul>
        </div>
        <div className="footer-links">
          <h4>Quick Links</h4>
          <ul>
            <li><a href="/about">About Us</a></li>
            <li><a href="/privacy-policy">Privacy Policy</a></li>
            <li><a href="/terms-of-service">Terms of Service</a></li>
            <li><a href="/contact">Contact</a></li>
          </ul>
        </div>
      </div>
      <div className="footer-bottom">
        <p>© 2024 <b>pasting.org</b>. All rights reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;
