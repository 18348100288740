import React from 'react';
import Layout from './Layout';

const PrivacyPolicy = () => {
  return (
    <Layout>
      <div className="policy-page">
        <h1>Privacy Policy</h1>
        <p>At Pasting.org, your privacy is our priority. We adhere to the highest standards to protect your personal information. This policy explains:</p>
        <ul>
          <li>What data we collect and why we collect it.</li>
          <li>How we use your data responsibly.</li>
          <li>Your rights to control your information.</li>
        </ul>
        <p>
          For more details, please contact us at <a href="/contact">Contact Us</a>.
        </p>
      </div>
    </Layout>
  );
};

export default PrivacyPolicy;
