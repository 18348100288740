import React from 'react';
import Layout from './Layout';

const TermsOfService = () => {
  return (
    <Layout>
      <div className="terms-page">
        <h1>Terms of Service</h1>
        <p>Welcome to Pasting.org! By using our platform, you agree to the following terms:</p>
        <ul>
          <li>You will not use Pasting.org to share illegal or harmful content.</li>
          <li>Content may be removed if it violates our community guidelines.</li>
        </ul>
        <p>
          For questions, visit our <a href="/contact">Contact Us</a> page.
        </p>
      </div>
    </Layout>
  );
};

export default TermsOfService;
