import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import ExpirationForm from './ExpirationForm';
import NotFound from './NotFound';
import ResultPage from './ResultPage';
import LoadingScreen from './LoadingScreen';
import About from './About';
import PrivacyPolicy from './PrivacyPolicy';
import TermsOfService from './TermsOfService';
import Contact from './Contact';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<ExpirationForm />} />
        <Route path="/not-found" element={<NotFound />} />
        <Route path="/about" element={<About />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms-of-service" element={<TermsOfService />} />
        <Route path="/contact" element={<Contact />} />
        {/* Route for the short URL */}
        <Route path="/:shortUrl" element={<LoadingScreen />} />
        <Route path="/p/:EncryptText" element={<ResultPage />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
  );
}

export default App;
